"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultValue = exports.readSession = exports.readAndParse = void 0;
const pulse_cis_sync_1 = require("@spt-tracking/pulse-cis-sync");
const uuid_1 = require("uuid");
const { USE_LOCALSTORAGE } = pulse_cis_sync_1.cookieConfig;
const SESSION_STORAGE_KEY = '_pulsesession';
const generateSessionSDRN = (sessionId) => `sdrn:schibsted:session:${sessionId}`;
// Return parsed json from cookie (or localStorage if enabled), or undefined.
const readAndParse = (storageKey) => {
    try {
        const storedSession = (0, pulse_cis_sync_1.readCookie)(storageKey);
        if (storedSession) {
            return JSON.parse(storedSession);
        }
    }
    catch (e) {
        // If localStorage/cookie is not available, we treat it as if
        // the value is undefined.
        // If data is unparseable, we also ignore it.
        return undefined;
    }
    return undefined;
};
exports.readAndParse = readAndParse;
// Read and parse session data from localStorage.
// Any invalid or unreadable data is ignored.
const readSession = () => {
    const storedSession = (0, exports.readAndParse)(SESSION_STORAGE_KEY);
    if (storedSession) {
        const [id, startTimeMs, lastEventTimeMs] = storedSession;
        const startTime = new Date(startTimeMs);
        const lastEventTime = new Date(lastEventTimeMs);
        if (id &&
            startTime &&
            lastEventTime &&
            !isNaN(startTime.getTime()) &&
            !isNaN(lastEventTime.getTime())) {
            return { id, startTime, lastEventTime };
        }
    }
    return undefined;
};
exports.readSession = readSession;
// Write session data to localStorage
const writeSession = (session) => {
    const { id, startTime, lastEventTime } = session;
    try {
        const sessionToStore = [id, startTime.getTime(), lastEventTime.getTime()];
        const halfHourInDays = 1.0 / (24 * 2);
        (0, pulse_cis_sync_1.writeCookie)(SESSION_STORAGE_KEY, JSON.stringify(sessionToStore), (0, pulse_cis_sync_1.getRootDomain)(), halfHourInDays);
        // Delete old localStorage-based session if needed
        if (!USE_LOCALSTORAGE && window && window.localStorage) {
            // removeItem will do nothing to a non-existing item.
            window.localStorage.removeItem(SESSION_STORAGE_KEY);
        }
    }
    catch (e) {
        // If we can't write the session, we just move on in life
    }
};
const createSession = () => {
    const now = new Date();
    return {
        id: generateSessionSDRN((0, uuid_1.v4)()),
        startTime: now,
        lastEventTime: now,
    };
};
const updateSession = (session) => {
    const now = new Date();
    const sessionMaxInterval = 30 * 60 * 1000; // 30 minutes in milliseconds
    if (!session || session.lastEventTime.getTime() < now.getTime() - sessionMaxInterval) {
        return createSession();
    }
    return Object.assign({}, session, { lastEventTime: now });
};
const defaultValue = () => undefined;
exports.defaultValue = defaultValue;
// Add session information to an event
// {
//   "session": {
//     "id": "sdrn:schibsted:session:123e4567-e89b-12d3-a456-426655440000",
//     "startTime": "2020-01-21T12:29:00.999Z"
//   }
// }
function session() {
    const oldSession = (0, exports.readSession)();
    const newSession = updateSession(oldSession);
    writeSession(newSession);
    const { id, startTime } = newSession;
    return {
        '@id': id,
        startTime: startTime.toISOString(),
    };
}
exports.default = session;
