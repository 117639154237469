"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultValue = exports.isActorInput = exports.generateActorSDRN = void 0;
const utils_1 = require("./utils");
/**
 * generateActorSDRN
 * Generates a well-formed sdrn for an actor, based on id, realm inputs
 * @param id String
 * @param realm String Realm is not required, so, without a fully formed "@id" in the actors input object, the "realm"
 * part of the sdrn is flagged with "__REALM_NOT_SET__", for early noticing implementation/integration errors.
 */
function generateActorSDRN(id, realm = '__REALM_NOT_SET__') {
    const outputId = id || '__USER_ID_NOT_SET__';
    return `sdrn:${realm}:user:${outputId}`;
}
exports.generateActorSDRN = generateActorSDRN;
function isActorInput(input) {
    return Object.prototype.hasOwnProperty.call(input, "actor");
}
exports.isActorInput = isActorInput;
function formatActorInput(input) {
    if ((0, utils_1.isString)(input)) {
        return { id: input };
    }
    const { id, realm, ...rest } = input || {};
    return { id, realm, ...rest };
}
/**
 * Function to generate a builder for Actor objects.
 */
function actor(input) {
    const { actor: actorInput } = input || {};
    if (!actorInput) {
        return undefined;
    }
    const { id, realm, ...inputProps } = formatActorInput(actorInput);
    // TODO: think in ways that a well-formed SDRN passed as @id in the input can render the "id" not necessary anymore.
    if (!id) {
        return undefined;
    }
    const actorSDRN = generateActorSDRN(id, realm);
    const baseActor = {
        '@id': actorSDRN,
        'spt:userId': actorSDRN,
    };
    return Object.assign(baseActor, inputProps);
}
exports.default = actor;
const defaultValue = () => undefined;
exports.defaultValue = defaultValue;
