"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultValue = exports.generateProviderSDRN = void 0;
const PROVIDER_ID_UNDEFINED = '--PROVIDER-ID-NOT-DEFINED--';
const DEFAULT_PROVIDER_TYPE = 'Organization';
function generateProviderSDRN(providerId) {
    if (providerId) {
        return `sdrn:schibsted:client:${providerId}`;
    }
    return PROVIDER_ID_UNDEFINED;
}
exports.generateProviderSDRN = generateProviderSDRN;
function provider(input) {
    const { provider: providerInput } = input || {};
    const { id, type = DEFAULT_PROVIDER_TYPE, ...rest } = providerInput || {};
    const base = {
        '@type': type,
        '@id': generateProviderSDRN(id),
    };
    return Object.assign({}, base, rest);
}
exports.default = provider;
const defaultValue = () => undefined;
exports.defaultValue = defaultValue;
