"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const warned = {};
const warnOnce = (id, ...data) => {
    if (warned[id]) {
        return;
    }
    warned[id] = true;
    console.warn(...data);
};
exports.default = warnOnce;
