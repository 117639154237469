"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultValue = void 0;
const version_json_1 = require("../version.json");
/**
 * Format tracker objects
 */
function tracker(input) {
    const { tracker: trackerInput } = input || {};
    const base = {
        name: 'Pulse Node.js SDK',
        type: 'JS',
        version: version_json_1.SDK_VERSION
    };
    return Object.assign({}, base, trackerInput);
}
exports.default = tracker;
const defaultValue = () => undefined;
exports.defaultValue = defaultValue;
