"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAdvertisingIdentifiers = exports.getIncludeAdvertising = exports.getAdId = exports.AdvertisingVendors = void 0;
const actor_1 = require("./actor");
const cis_1 = require("./cis");
var AdvertisingVendors;
(function (AdvertisingVendors) {
    AdvertisingVendors["Xandr"] = "xandr";
    AdvertisingVendors["Delta"] = "delta";
    AdvertisingVendors["Adform"] = "adform";
})(AdvertisingVendors || (exports.AdvertisingVendors = AdvertisingVendors = {}));
/**
 * Get the includeAdvertising flag, set on initialisation.
 * @private
 * @see constructor
 * @category Identifiers
 */
const getIncludeAdvertising = (sdkState) => {
    return sdkState.includeAdvertising;
};
exports.getIncludeAdvertising = getIncludeAdvertising;
/**
 * Get the advertisement id (adId) from the current browser. A promise is returned
 * because a CIS call is done to generate a new adId if no cookie is
 * found.
 *
 * *Note*: This method will return a Promise that is always rejected on node
 * environments.
 * @category Identifiers
 */
const getAdId = async (builders, sdkState) => {
    if (!getIncludeAdvertising(sdkState)) {
        return undefined;
    }
    const { adId } = await (0, cis_1.cisSync)({ actor: (0, actor_1.getActor)(builders) }, builders, sdkState);
    return adId;
};
exports.getAdId = getAdId;
/**
 * Get the Advertisement Identifiers for a Vendor or all Vendors.
 * @param builders
 * @param sdkState
 * @param [vendors]
 * @category Identifiers
 */
const getAdvertisingIdentifiers = async (builders, sdkState, vendors) => {
    vendors = (!vendors || vendors.length === 0) ? [AdvertisingVendors.Xandr] : vendors;
    const { adId, ppId } = await (0, cis_1.cisSync)({ actor: (0, actor_1.getActor)(builders) }, builders, sdkState);
    const identifiers = {};
    if (!getIncludeAdvertising(sdkState) || !ppId)
        return identifiers;
    vendors.forEach(vendor => {
        if (ppId[vendor]) {
            identifiers[vendor] = { ...ppId[vendor] };
        }
        if (vendor === AdvertisingVendors.Xandr) {
            identifiers[vendor].adId = adId;
        }
    });
    return identifiers;
};
exports.getAdvertisingIdentifiers = getAdvertisingIdentifiers;
