"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cisSync = exports.clearCookieJweIfStale = void 0;
const pulse_cis_sync_1 = require("@spt-tracking/pulse-cis-sync");
Object.defineProperty(exports, "clearCookieJweIfStale", { enumerable: true, get: function () { return pulse_cis_sync_1.clearCookieJweIfStale; } });
const actor_1 = require("./actor");
const version_json_1 = require("../version.json");
/**
 * Sync our ids with cis given the userId from the event or builders.
 * @param data the event parameters (the data passed to `.track()`),
 * if called in a context where an event is being sent.
 *
 * @param builders
 * @param config
 * @returns the parsed pulse cookie after syncing with cis.
 * @category Identifiers
 */
const cisSync = async (data, builders, config) => {
    // At this point, neither this.builders nor data have been
    // evaluated through evaluateAndFlatten (this happens in `.track`),
    // so in the rare and weird case where `.actor` is a function or
    // a promise, or contains functions or promises, we will evaluate
    // these twice.
    // Note that this evaluation isn't 100% correct in that it ignores
    // things like eventDefaults, doesn't call the event builder associated
    // with the event type etc., but this shouldn't affect `.actor.{id,realm}`
    // in cases I can imagine (of course, my imagination is limited)
    //
    // we need an event, or at least this.builders.actor
    // to know the correct login/logout state for the user.
    // In some implementations (video plugin) the client will
    // populate the full event only on `.track` and not ever
    // call `.update(...)` with the login status.
    // Arguably this is incorrect, and `.update(...)` should be
    // called when login state changes, but we try to make
    // the API as fail-proof as possibly by handling the situation
    // here.
    //
    // However, we do cis syncs without access to an event, so
    // for this case, we need to just reuse the userId from the existing
    // _pulse2data cookie, or just not do the sync.
    //
    const actor = await (0, actor_1.evaluateActor)(data, builders);
    const { id, realm } = actor;
    const { cisBaseUrl, cisEnabled, includeAdvertising, isHybrid, nativeJwe, providerId, trackerId, vendors, xandrEnabled, } = config;
    if (cisEnabled) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return (0, pulse_cis_sync_1.syncWithCis)(id, nativeJwe, cisBaseUrl, realm, undefined, xandrEnabled, providerId, isHybrid ? 'Hybrid' : 'Web', version_json_1.SDK_VERSION, {
            includeAdvertising,
            vendors,
            trackerId,
        });
    }
    return { doTracking: true };
};
exports.cisSync = cisSync;
