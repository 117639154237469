"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEnvironmentId = void 0;
const actor_1 = require("./actor");
const cis_1 = require("./cis");
/**
 * Get the environment id from the current browser. A promise is returned
 * because a CIS call is done to generate a new environmentId if no cookie is
 * found.
 *
 * *Note*: This method will return a Promise that is always rejected on node
 * environments.
 * @category Identifiers
 */
const getEnvironmentId = async (builders, sdkState) => {
    const actor = await (0, actor_1.getActor)(builders);
    const { environmentId } = await (0, cis_1.cisSync)({ actor }, builders, sdkState);
    return environmentId;
};
exports.getEnvironmentId = getEnvironmentId;
