"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultValue = void 0;
const LOCATION_TYPE = 'GeoCoordinates';
function geoToFixed(pos) {
    if (pos !== null && pos !== undefined && pos !== '' && !Number.isNaN(pos)) {
        return (Math.round(Number(pos) * 1000) / 1000.0).toFixed(3);
    }
}
/**
 * Create a valid Location object according to schema. Will create the
 * required '@type' field, and reduce `latitude` and `longitude` accuracy
 * to 3 decimal places.
 *
 * "altitude" is dropped from the result.
 *
 * Other input will be merged with the object as is.
 *
 * @see https://github.schibsted.io/spt-dataanalytics/event-formats/blob/master/schema/master/objects/GeoCoordinates.json
 */
function location(input) {
    const { location: locationInput } = input || {};
    const { latitude: lat, longitude: long, altitude: _, ...rest } = locationInput || {}; // "altitude" is dropped
    const latitude = geoToFixed(lat);
    const longitude = geoToFixed(long);
    if (latitude || longitude) {
        return {
            '@type': LOCATION_TYPE,
            ...rest,
            latitude,
            longitude,
        };
    }
}
exports.default = location;
function defaultValue() {
    return undefined;
}
exports.defaultValue = defaultValue;
