/**
 * This function pushes "provide" calls in the call queue to the front of the queue. This is done
 * because a "require" call without a "provide" call will halt the call queue executor.
 *
 */
export default function (list) {
  const provideSteps = [];
  const otherSteps = [];
  list.forEach((step) => {
    const value = step instanceof Promise ? step.inputValue[0] : step[0];
    if (value === 'provide') {
      provideSteps.push(step);
    } else {
      otherSteps.push(step);
    }
  });
  return provideSteps.concat(otherSteps);
}
