"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultValue = void 0;
function generateExperimentSDRN(id, experimentationPlatform) {
    return `sdrn:${experimentationPlatform}:experiment:${id}`;
}
/**
 * Attempts to format a correct "@id" given the input.
 * Allows for the "@id" property to be completely overridden by the dev's input
 * @param experiment
 */
function formatExperiment(experiment) {
    const { id, platform, ...rest } = experiment || {};
    return {
        '@id': generateExperimentSDRN(id, platform),
        ...(platform && { platform }),
        ...rest,
    };
}
/**
 * Experiments builder
 * @param input A full Pulse Event, from where the "experiments" property is parsed.
 */
function experiments(input) {
    const experimentList = (input || {}).experiments || [];
    if (experimentList.length > 0) {
        return experimentList.map(formatExperiment);
    }
}
exports.default = experiments;
const defaultValue = () => undefined;
exports.defaultValue = defaultValue;
