"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_LEAVE_EVENT_BUILDER = exports.DEFAULT_PAGE_LEAVE_EVENT = exports.DEFAULT_VIEWED_CONTENT = exports.DEFAULT_ACTIVITY_DURATION = void 0;
const LEAVE_EVENT_TYPE = 'Leave';
const DEFAULT_ACTIVITY_DURATION = () => ({
    value: 0,
    interval: undefined,
    timeSinceLastActivity: 0
});
exports.DEFAULT_ACTIVITY_DURATION = DEFAULT_ACTIVITY_DURATION;
const DEFAULT_VIEWED_CONTENT = () => ({
    objectViewPercentage: 0,
    maxObjectViewPercentage: 0,
    pageViewPercentage: 0,
    maxPageViewPercentage: 0,
    pageScrollPosition: 0,
    maxPageScrollPosition: 0,
});
exports.DEFAULT_VIEWED_CONTENT = DEFAULT_VIEWED_CONTENT;
const DEFAULT_PAGE_LEAVE_EVENT = () => ({
    type: LEAVE_EVENT_TYPE,
    engagement: (0, exports.DEFAULT_VIEWED_CONTENT)()
});
exports.DEFAULT_PAGE_LEAVE_EVENT = DEFAULT_PAGE_LEAVE_EVENT;
const DEFAULT_LEAVE_EVENT_BUILDER = () => () => ({});
exports.DEFAULT_LEAVE_EVENT_BUILDER = DEFAULT_LEAVE_EVENT_BUILDER;
