"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const emptyFn = () => { void (0); };
function default_1(value) {
    let res = emptyFn;
    let rej = emptyFn;
    const promise = new Promise((resolve, reject) => {
        res = resolve;
        rej = reject;
    });
    return {
        promise,
        resolve: res,
        reject: rej,
        inputValue: value,
    };
}
exports.default = default_1;
