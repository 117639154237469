"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.configPro = exports.configPre = exports.configDev = void 0;
const COLLECTOR_PATH = 'api/v1/track';
const endpointsCollector = {
    DEV: {
        URL: 'https://collector.pulse-internal-dev.schibsted.io',
        PATH: COLLECTOR_PATH,
    },
    PRE: {
        URL: 'https://collector.pulse-internal-pre.schibsted.io',
        PATH: COLLECTOR_PATH,
    },
    PRO: {
        URL: 'https://collector.schibsted.io',
        PATH: COLLECTOR_PATH,
    },
};
const endpointsCis = {
    DEV: {
        URL: 'https://cis.pulse-internal-dev.schibsted.io',
    },
    PRE: {
        URL: 'https://cis.pulse-internal-pre.schibsted.io',
    },
    PRO: {
        URL: 'https://cis.schibsted.com',
    },
};
const baseConfig = {
    cisEnabled: true,
    eventDebounce: 200,
    environment: 'browser',
    useBeaconWhenAvailable: true,
};
exports.configDev = {
    ...baseConfig,
    cisBaseUrl: endpointsCis.DEV.URL,
    collectorBaseUrl: endpointsCollector.DEV.URL,
    collectorPath: endpointsCollector.DEV.PATH,
};
exports.configPre = {
    ...baseConfig,
    cisBaseUrl: endpointsCis.PRE.URL,
    collectorBaseUrl: endpointsCollector.PRE.URL,
    collectorPath: endpointsCollector.PRE.PATH,
};
exports.configPro = {
    ...baseConfig,
    cisBaseUrl: endpointsCis.PRO.URL,
    collectorBaseUrl: endpointsCollector.PRO.URL,
    collectorPath: endpointsCollector.PRO.PATH,
};
exports.default = exports.configPro;
