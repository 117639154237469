"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultValue = void 0;
const utils_1 = require("./utils");
/**
 * Format experiment metadata
 */
function experimentMetadata(input) {
    const { errors, assignmentId, requestTimestamp } = (input || {}).experimentMetadata || {};
    const formattedExperimentMetadata = {};
    if (errors && errors.length) {
        formattedExperimentMetadata.errors = errors;
    }
    if (assignmentId) {
        formattedExperimentMetadata.assignmentId = assignmentId;
    }
    if (requestTimestamp) {
        formattedExperimentMetadata.requestTimestamp = requestTimestamp;
    }
    return (0, utils_1.isObjectEmpty)(formattedExperimentMetadata)
        ? undefined
        : formattedExperimentMetadata;
}
exports.default = experimentMetadata;
function defaultValue() {
    return undefined;
}
exports.defaultValue = defaultValue;
