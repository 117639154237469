"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultValue = void 0;
const pulse_cis_sync_1 = require("@spt-tracking/pulse-cis-sync");
const device_node_1 = require("./device-node");
const utils_1 = require("./utils");
__exportStar(require("./device-node"), exports);
function supportsLocalStorage() {
    try {
        const { localStorage } = window;
        localStorage.setItem('pulse4test', 'check');
        localStorage.removeItem('pulse4test');
        return true;
    }
    catch (e) {
        return false;
    }
}
function getViewportDimensions() {
    let viewportWidth;
    let viewportHeight;
    const { document } = window;
    if (typeof window.innerWidth !== 'undefined') {
        viewportWidth = window.innerWidth;
        viewportHeight = window.innerHeight;
    }
    else if (typeof document.documentElement.clientWidth !== 'undefined' &&
        document.documentElement.clientWidth !== 0) {
        viewportWidth = document.documentElement.clientWidth;
        viewportHeight = document.documentElement.clientHeight;
    }
    else {
        viewportWidth = document.getElementsByTagName('body')[0].clientWidth;
        viewportHeight = document.getElementsByTagName('body')[0].clientHeight;
    }
    return `${viewportWidth}x${viewportHeight}`;
}
const defaultValue = () => {
    if (!window) {
        return {};
    }
    const { navigator, screen } = window;
    return {
        acceptLanguage: navigator.userLanguage || navigator.language,
        screenSize: `${screen.width}x${screen.height}`,
        userAgent: navigator.userAgent,
        deviceType: (0, utils_1.deviceType)(window.navigator.userAgent),
        viewportSize: getViewportDimensions(),
        localStorageEnabled: supportsLocalStorage(),
    };
};
exports.defaultValue = defaultValue;
function default_1(input) {
    const { device: deviceInput } = input || {};
    const { environmentId, jwe } = (0, pulse_cis_sync_1.parsePulseCookies)();
    const data = {
        id: environmentId,
        jweIds: jwe,
        ...((0, utils_1.isString)(deviceInput) ? { id: deviceInput } : deviceInput),
    };
    return (0, device_node_1.device)(exports.defaultValue)({ device: data });
}
exports.default = default_1;
